import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { ErrorsComponent } from './errors-component/errors.component';
import { ErrorsHandler } from './errors-handler/errors-handler';
import { ErrorsService } from './errors-service/errors.service';
import { ServerErrorInterceptor } from './server-errors-interceptor/server-errors.interceptor';

@NgModule({ declarations: [ErrorsComponent],
    exports: [ErrorsComponent], imports: [CommonModule, RouterModule, SharedModule], providers: [
        ErrorsService,
        {
            provide: ErrorHandler,
            useClass: ErrorsHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class ErrorsModule {}
